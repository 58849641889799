

@font-face {
  font-family: 'Framework7 Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Framework7 Icons'), local('Framework7Icons-Regular'),
  url('../public/fonts/Framework7Icons-Regular.woff2') format('woff2'), url('../public/fonts/Framework7Icons-Regular.woff')
  format('woff'), url('../public/fonts/Framework7Icons-Regular.ttf') format('truetype');
}

.f7-icons,
.framework7-icons {
  font-family: 'Framework7 Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 28px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: 'liga';
  -moz-font-feature-settings: 'liga=1';
  -moz-font-feature-settings: 'liga';
  font-feature-settings: 'liga';
}

.cards-ctn {
  height: 200px;
  display: flex;
  color: white;
}

.custom-file-input {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.pre-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

}

.custom-file-label {
  color: #007AFF;
  width: 100%;
  background-color: transparent;
  cursor: pointer;
}

.file-input-ctn:active {
  border-radius: 10px;
  background-color: rgba(128, 128, 128, 0.2);
}


input[type="file"]::-webkit-file-upload-button{
  right: 1rem;
  position: absolute;
  width: 100%
}

.custom-toast-class {
  border-radius: 10px !important;
  background-color: rgba(24, 24, 24, 0.9) !important;
  backdrop-filter: blur(6px) !important;
  color: white !important;
  padding: 10px 2px !important;
  transform: translateY(-20%) !important;
  width: 89vw !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.custom-toast-class .custom-toast-content .toast-title {
  font-size: 1.4em;
}


.searchbar {
  height: 50px !important;
  background-color: transparent;
}

.custom-tab {
  position: relative;
  padding: 10px 20px;
  color: #888;
  transition: color 0.3s ease;
}

.custom-tab .tab-text {
  position: relative;
}

.custom-tab .tab-text::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #007aff;
  transition: width 0.3s ease;
}

.custom-tab .tab-text.active {
  color: #007aff;
}

.custom-tab .tab-text.active::after {
  width: 100%;
}

.custom-tab-content {
  margin-top: 20px;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  gap:7px;
  padding: 150px;
  justify-items: center;
}

.product-card {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  overflow: hidden;
  height: 240px;
  width: 45vw;
}

.custom-searchbar-container {
  display: flex;
  align-items: center;
  height: 50px;
}

.chat-logo {
  height: 30px;
  margin-left: 10px;
  margin-right: 2px;
}

.searchbar-wrapper {
  flex: 1;
  overflow: hidden;

}

option[data-option-image] {
  width: 96px;
  height: 96px;
  border-radius: 12px;
  object-fit: cover;
}

.custom-toast {
  bottom: 20px !important;
  left: 21px !important;
  right: 21px !important;
  width: auto !important; /* Allow width to adjust automatically */
  max-width: calc(100% - 40px) !important; /* Ensure it fits within the viewport */
  border-radius: 16px;/* Remove centering */

}

.custom-toast .toast-content {
  background-color: rgba(45, 45, 45, 6);/* Original color with transparency */
  -webkit-backdrop-filter: blur(10px);       /* Apply blur effect for Safari */
  backdrop-filter: blur(10px) !important;               /* Apply blur effect */
  border-radius: 20px;                       /* Keep the border radius */
}

.custom-searchbar {
  --f7-searchbar-input-height: 40px;
  margin-left: 20px;

}

.custom-searchbar .searchbar-input-wrap input {
  border-radius: 15px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.right-icons {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.delete-button:active {
  border-radius: 10px;
  background-color: rgba(245, 56, 56, 0.1);
}





